import React from 'react';

import Layout from '../../layouts';
import Container from '../../components/Container';
import PageTitle from '../../components/Subpage/title';
import withAuth from '../../components/Account/auth';
import AccountLayout from '../../components/Account';
import AccountDashboard from '../../components/Account/dashboard';

// import loadComponents from '../../components/Loadable';

// const AccountLayout = loadComponents('account');
// const AccountDashboard = loadComponents('account-dashboard');

const page = { name: 'My Account', slug: 'account' };

const AccountIndexBase = withAuth(props => (
  <Container>
    <AccountLayout logoutUser={props.logoutUser}>
      <AccountDashboard authUser={props.authUser} firebase={props.firebase} />
    </AccountLayout>
  </Container>
));

export default function AccountIndex({ location }) {
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout title={page.name} location={`/${page.slug}/`} prevPage={prevPage}>
      <PageTitle title={page.name} />
      <AccountIndexBase />
    </Layout>
  );
}
