import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';
import AccountSidebar from './sidebar';

export default function Account({ children, logoutUser }) {
  return (
    <Row align="flex-start" justify="space-between">
      <Col xl={{ width: '70%' /* , order: 2 */ }}>{children}</Col>
      <Col xl={{ width: '25%' /* , order: 1 */ }}>
        <AccountSidebar logoutUser={logoutUser} />
      </Col>
    </Row>
  );
}
